/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';

import { ArrowForwardIcon } from 'bundles/ai-course-coach/assets/mui';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { CoachButton } from 'bundles/ai-course-coach/components/common';

type Props = {
  buttonText: string;
  handleNavButtonClick: () => void;
  'aria-describedby': string;
};

/**
 * Wrapper around CoachButton for navigation specific use cases.
 */
export const NavigationButton = (props: Props): React.ReactElement => {
  const { buttonText, handleNavButtonClick, 'aria-describedby': ariaDescribedBy } = props;

  return (
    <CoachButton
      aria-describedby={ariaDescribedBy}
      icon={<ArrowForwardIcon color="invert" />}
      size="small"
      fullWidth
      justifyContent="space-between"
      onClick={handleNavButtonClick}
    >
      {buttonText}
    </CoachButton>
  );
};

export default NavigationButton;
