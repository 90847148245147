/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { isRightToLeft } from 'js/lib/language';

import type { IconColor, IconSize } from 'bundles/ai-course-coach/assets/iconTypes';

import _t from 'i18n!nls/learning-assistant-chat-ai';

const styles = {
  root: (isRTL: boolean) => css`
    transform: ${isRTL ? 'rotate(180deg)' : 'none'};
  `,
};

/**
 * Rebrand MUI icon for "arrow forward"
 */
const ArrowForward = ({ size = 16, color = 'default' }: { size?: IconSize; color?: IconColor }) => (
  <svg
    css={styles.root(isRightToLeft(_t.getLocale()))}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3191 8.61702H1.78723C1.56028 8.61702 1.37234 8.54255 1.2234 8.39362C1.07447 8.24468 1 8.05674 1 7.82979C1 7.61702 1.07447 7.43617 1.2234 7.28723C1.37234 7.1383 1.56028 7.06383 1.78723 7.06383H12.3191L7.59574 2.34043C7.43972 2.1844 7.3617 2 7.3617 1.78723C7.3617 1.57447 7.43972 1.39007 7.59574 1.23404C7.73759 1.07801 7.91844 1 8.1383 1C8.35816 1 8.5461 1.07801 8.70213 1.23404L14.766 7.29787C14.8369 7.36879 14.8936 7.45035 14.9362 7.54255C14.9787 7.63475 15 7.7305 15 7.82979C15 7.94326 14.9787 8.0461 14.9362 8.1383C14.8936 8.2305 14.8369 8.31206 14.766 8.38298L8.7234 14.4255C8.55319 14.5816 8.3617 14.6596 8.14894 14.6596C7.93617 14.6596 7.75886 14.5816 7.61702 14.4255C7.46099 14.2695 7.38298 14.0851 7.38298 13.8723C7.38298 13.6596 7.46099 13.4752 7.61702 13.3191L12.3191 8.61702Z"
      fill={color === 'invert' ? 'var(--cds-color-white-0)' : 'var(--cds-color-grey-950)'}
    />
  </svg>
);

export default ArrowForward;
