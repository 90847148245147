/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

import { Skeleton } from '@coursera/cds-core';

import { CoachBrandLabel, CoachBrandLabelWithBeta } from 'bundles/ai-course-coach/assets/coachBranding';
import type { CdsDisclosureProps } from 'bundles/ai-course-coach/components/common';
// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { CdsDisclosure } from 'bundles/ai-course-coach/components/common';
import ShowMoreDisclosureButtons from 'bundles/ai-course-coach/components/common/ShowMoreDisclosureButtons';

import _t from 'i18n!nls/learning-assistant-chat-ai';

const DisclosureSkeleton = () => {
  return (
    <div className="coach-disclosure-skeleton">
      <Skeleton variant="text" height={24} />
      <Skeleton variant="text" width="50%" height={24} />

      <div
        css={css`
          display: flex;
          gap: 8px;
          margin: var(--cds-spacing-200) 0;
        `}
      >
        <Skeleton
          variant="rect"
          width={120}
          height={36}
          css={css`
            border-radius: var(--cds-border-radius-100);
          `}
        />
        <Skeleton
          variant="rect"
          width={120}
          height={36}
          css={css`
            border-radius: var(--cds-border-radius-100);
          `}
        />
      </div>
    </div>
  );
};

export type Props = Omit<CdsDisclosureProps, 'header' | 'aria-label'> & {
  'aria-label'?: string;
  actionButtons?: React.ReactNode[];
  className?: SerializedStyles;
  showBetaTag?: boolean;
  onToggle?: (expanded: boolean) => void;

  /**
   * Shows a skeleton version of the disclosure
   * @default false
   */
  loading?: boolean;
};

/**
 * Rebrand of CoachDisclosure - simple wrapper around Disclosure for Coach-specific use cases.
 */
export const CoachDisclosure = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const {
    loading = false,
    showBetaTag = false,
    actionButtons,
    'aria-label': ariaLabel = _t('#{coachLabel}, AI powered assistant, beta', { coachLabel: 'Coach' }),
    children,
    onToggle,
    ...rest
  } = props;

  const showFooter = loading ? false : !!actionButtons;

  return (
    <CdsDisclosure
      {...rest}
      ref={ref}
      aria-label={ariaLabel}
      header={
        <div css={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          {showBetaTag ? <CoachBrandLabelWithBeta /> : <CoachBrandLabel />}
        </div>
      }
      footer={
        showFooter && !!actionButtons ? <ShowMoreDisclosureButtons>{actionButtons}</ShowMoreDisclosureButtons> : null
      }
      onToggle={onToggle}
    >
      {loading && <DisclosureSkeleton />}
      {!loading && children}
    </CdsDisclosure>
  );
});

export default CoachDisclosure;
