import * as React from 'react';
import { Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import { supportedSubfolderLocaleCodes } from 'bundles/internationalization-lib/constants/subfolderLocaleCodes';
import NotFound from 'bundles/phoenix/components/NotFound';
import SeoSearchApp from 'bundles/seo-entity-page/components/SeoSearchApp';

const SeoSearchPage = loadOnRoute(() => import('bundles/seo-entity-page/components/SeoSearchPage'));

export default (
  <CourseraRoute
    path="/"
    component={SeoSearchApp}
    handle={{
      tracking: {
        data: (_, location) => {
          const currentPage = location?.query?.page;

          return {
            page: {
              segment: 'consumer',
              type: 'entity_query_page',
              pagination: {
                pageNumber: currentPage ? Number(currentPage) : 1,
              },
            },
          };
        },
      },
    }}
  >
    {/* Routes for subfolder localized EQP page */}
    {supportedSubfolderLocaleCodes.map((subfolderLocaleCode) => (
      <Route path={`${subfolderLocaleCode}/courses`} getComponent={SeoSearchPage} key={subfolderLocaleCode} />
    ))}

    <Route path="courses" getComponent={SeoSearchPage} />

    {/* This catch-all *, status=404 route is important for SSR. */}
    <Route path="*" component={NotFound} />
  </CourseraRoute>
);
