import * as React from 'react';

import type { IconSize } from 'bundles/ai-course-coach/assets/iconTypes';

/**
 * MUI icon for "Minus"
 */
const Minus = ({ size = 16 }: { size?: IconSize }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.875 8.72635C1.62669 8.72635 1.41882 8.64074 1.25139 8.46953C1.0838 8.29831 1 8.09336 1 7.85466C1 7.61597 1.0838 7.41385 1.25139 7.24831C1.41882 7.08277 1.62669 7 1.875 7H14.1486C14.3817 7 14.5818 7.08419 14.7491 7.25257C14.9164 7.42079 15 7.62425 15 7.86294C15 8.10163 14.9164 8.30525 14.7491 8.47378C14.5818 8.64216 14.3817 8.72635 14.1486 8.72635H1.875Z"
      fill="var(--cds-color-grey-950)"
    />
  </svg>
);

export default Minus;
